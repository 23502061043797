.user-select-none {
  -webkit-touch-callout: none;
  /* iOS Safari */
  -webkit-user-select: none;
  /* Chrome/Safari/Opera */
  -khtml-user-select: none;
  /* Konqueror */
  -moz-user-select: none;
  /* Firefox */
  -ms-user-select: none;
  /* Internet Explorer/Edge */
  user-select: none;
}
.workbench {
  background-color: #f5f5f5;
  min-height: 100vh;
  -webkit-touch-callout: none;
  /* iOS Safari */
  -webkit-user-select: none;
  /* Chrome/Safari/Opera */
  -khtml-user-select: none;
  /* Konqueror */
  -moz-user-select: none;
  /* Firefox */
  -ms-user-select: none;
  /* Internet Explorer/Edge */
  user-select: none;
}
.workbench .wing-blank {
  padding: 0 18px;
  background-color: #f5f5f5;
}
.workbench .line-gap {
  padding: 6px 0;
}
.workbench .adm-swiper {
  border-radius: 8px;
  overflow: hidden;
}
.workbench .adm-swiper .swiper-img-box {
  height: 10.66666667rem;
  width: 100%;
}
.workbench .adm-swiper .swiper-img-box img {
  display: block;
  width: 100%;
  height: 100%;
  object-fit: cover;
  border-radius: 8px;
  overflow: hidden;
}
.workbench .cate-box {
  background: #fff;
  border-radius: 5px;
  overflow: hidden;
  padding: 16px 0;
}
.workbench .cate-box .cate-name {
  font-size: 18px;
  line-height: 24px;
  text-indent: 18px;
  font-weight: 600;
}
.workbench .cate-box .app-box {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
}
.workbench .cate-box .app-box .app-item {
  position: relative;
  width: 20%;
  margin: 0 2.5%;
  font-size: 14px;
  line-height: 26px;
  margin-top: 20px;
  text-align: center;
}
.workbench .cate-box .app-box .app-item img {
  width: 32px;
  height: 32px;
  border-radius: 32px;
}
.workbench .cate-box .app-box .app-item .del-icon {
  width: 20px;
  height: 20px;
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 100%;
  background-color: red;
  position: absolute;
  top: -10px;
  right: 0;
  color: #fff;
}
.workbench .cate-box .app-box .app-item .del-icon .del {
  display: block;
  width: 8px;
  height: 1px;
  background-color: #fff;
}
.workbench .no-data {
  padding-top: 8rem;
  margin: 0 auto;
  text-align: center;
}
.workbench .no-data .tips {
  padding-top: 10px;
  color: #888;
}
